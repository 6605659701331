/*
    Recarregar elementos DOM
 */

document.addEventListener("livewire:load", () => {
    Livewire.hook('message.processed', (message, component) => {

        $('[data-toggle="tooltip"]').tooltip();

        /**
         *  Set Masks
         */
        cash(".imask-money").each(function () {
            SimpleMaskMoney.setMask((this), {
                allowNegative: false,
                negativeSignAfter: false,
                prefix: '',
                suffix: '',
                fixed: true,
                fractionDigits: 2,
                decimalSeparator: ',',
                thousandsSeparator: '.',
                cursor: 'end'
            })
        });

        cash(".imask-moneyd1").each(function () {
            SimpleMaskMoney.setMask((this), {
                allowNegative: false,
                negativeSignAfter: false,
                prefix: '',
                suffix: '',
                fixed: true,
                fractionDigits: 1,
                decimalSeparator: '.',
                thousandsSeparator: '',
                cursor: 'end'
            })
        });

        /**
         *  Set Masks
         */
        cash(".imask-cnpj").each(function () {
            IMask((this), {
                mask: [
                    {
                        mask: '00.000.000/0000-00',
                        lazy: false,
                        placeholderChar: '_',
                    }
                ]
            });
        });

        cash(".imask-cpf").each(function () {
            IMask((this), {
                mask: [
                    {
                        mask: '000.000.000-00',
                        lazy: false,
                        placeholderChar: '_',
                    }
                ]
            });
        });

        cash(".imask-number").each(function () {
            /* https://imask.js.org/guide.html#masked-number */
            IMask(this, {
                mask: [{
                    mask: Number,
                    lazy: false,
                    scale: 2,  // digits after point, 0 for integers
                    signed: false,  // disallow negative
                    thousandsSeparator: '',  // any single char
                    padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
                    normalizeZeros: false,  // appends or removes zeros at ends
                    radix: '.',  // fractional delimiter
                    //mapToRadix: ['.'],  // symbols to process as radix
                    min: this.min !== '' ? this.min : 0,
                    max: this.max !== '' ? this.max : 100
                }]
            });
        });

        cash(".imask-cnpj-cpf").each(function () {
            IMask((this), {
                mask: [
                    {
                        mask: '000.000.000-00',
                        lazy: false,
                        placeholderChar: '_',
                    },
                    {
                        mask: '00.000.000/0000-00',
                        lazy: false,
                        placeholderChar: '_',
                    }
                ]
            });
        });

        cash(".imask-celular").each(function () {
            IMask(this, {
                mask: [{
                    mask: '(00)00000-0000',
                    lazy: false,
                    placeholderChar: '_'
                }]
            });
        });

        cash(".imask-telefone").each(function () {
            IMask(this, {
                mask: [{
                    mask: '(00)0000-0000',
                    lazy: false,
                    placeholderChar: '_'
                }]
            });
        });

        cash(".imask-email").each(function () {
            IMask(this, {
                mask: [{
                    mask: /^\S*@?\S*$/,
                    lazy: false,
                    placeholderChar: '_'
                }]
            });
        });

        cash(".imask-mumber").each(function () {
            /* https://imask.js.org/guide.html#masked-number */
            IMask(this, {
                mask: [{
                    mask: Number,
                    lazy: false,
                    scale: 2,  // digits after point, 0 for integers
                    signed: false,  // disallow negative
                    thousandsSeparator: '',  // any single char
                    padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
                    normalizeZeros: false,  // appends or removes zeros at ends
                    radix: '.',  // fractional delimiter
                    //mapToRadix: ['.'],  // symbols to process as radix
                    min: 0,
                    max: 10000
                }]
            });
        });

        cash(".imask-cep").each(function () {
            IMask((this), {
                mask: [{
                    mask: '00.000-000',
                    lazy: false,
                    placeholderChar: '_'
                }]
            });
        });

        /*
            Select
         */
        cash(".tom-select").each(function () {
            var select = (this);
            let options = {
                plugins: {
                    dropdown_input: {},
                },
                persist: false,
                create: false,
                render: {
                    no_results: function (data, escape) {
                        return '<div class="no-results">Nenhum resultado para "' + escape(data.input) + '"</div>';
                    }
                }
            };

            if (cash(this).attr("multiple") !== undefined) {
                options = {
                    ...options,
                    plugins: {
                        ...options.plugins,
                        remove_button: {
                            title: "Remover este item?",
                        },
                    },
                };
            }

            var instance = select.tomselect;

            new TomSelect(this, options);

            if (
                (component.serverMemo.errors[select.getAttribute('wire:model.lazy')]) ||
                (component.serverMemo.errors[select.getAttribute('wire:model.defer')]) ||
                (component.serverMemo.errors[select.getAttribute('wire:model')])
            ) {
                let ts_pai = select.id + '-ts-control';
                let tom_element = document.querySelector('#' + ts_pai)
                tom_element.classList.add('form-control')
                tom_element.classList.add('is-invalid')
            }
        });

        // Feather Icons
        /*
        feather.replace({
            "stroke-width": 1.5,
        });*/
    });
});

import Echo from 'laravel-echo';

try {
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: window.socket_io_url,
    });
} catch (e) {
    console.error(e);
}
